import styles from "./styles.module.scss";
import { Container } from "@mui/material";
import ArrowLeft, { NotFoundPageIcon } from "../../assets/icons";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/dashboard");
    window.location.reload();
  };

  return (
    <Container className={styles.box}>
      <div className={styles.flexbox}>
        <div className={styles.error}>
          <h2 className={styles.message}>Something wrong error...</h2>
          <p className={styles.description}>
            Sorry, the data you are looking for doesn't exist.
          </p>
          <div className={styles.buttons}>
            <button onClick={goBack} className={styles.backButton}>
              <ArrowLeft /> Go back
            </button>
            <button
              onClick={() => window.location.reload()}
              className={styles.homeButton}
            >
              Refresh
            </button>
          </div>
        </div>
        <div className={styles.errorGraphic}>
          <NotFoundPageIcon />
        </div>
      </div>
    </Container>
  );
}
