import React, { useState } from "react";
import styles from "./styles.module.scss";
import GalleryPopup from "../../../Gallery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import classNames from "classnames";

const UrlImageMessage = ({ link, isMergedMedia, isOther }) => {
  const [openGallery, setOpenGallery] = useState(false);

  const gallery = link?.map((item) => ({
    original: item,
    thumbnail: item,
  }));

  return (
    <>
      <LazyLoadImage
        width={"100%"}
        alt="default"
        className={classNames(styles.url_image, {
          [styles.merged]: isMergedMedia,
          [styles.other]: isOther,
        })}
        effect="blur"
        onClick={() => setOpenGallery(true)}
        src={link?.[0] || "/default_image.png"}
      />
      <GalleryPopup
        isOpen={openGallery}
        close={() => setOpenGallery(false)}
        images={gallery}
      />
    </>
  );
};

export default UrlImageMessage;
