import React, { useMemo, useState } from "react";
import styles from "./style.module.scss";
import moment from "moment";
import {
  IncommingIcon,
  OutgoingIcon,
  PhoneCallIcon,
} from "../../../../assets/icons";
import AudioMessage from "../AudioMessage/AudioMessage";
import ImageMessage from "./components/ImageMessage";
import VideoMessage from "./components/VideoMessage";
import TextMessage from "./components/TextMessage";
import FileMessage from "./components/FileMessage";
import ContactMessage from "./components/ContactMessage";
import MapMessage from "./components/MapMessage";
import { TELEGRAM_CDN_URL } from "../../../../utils/telegramApi";
import AdditionalFunctions from "../AdditionalFunctions";
import useChats from "../../../../hooks/api/useChatsAPI";
import { useParams } from "react-router-dom";

const Renderers = {
  audio: AudioMessage,
  image: ImageMessage,
  video: VideoMessage,
  file: FileMessage,
  contact: ContactMessage,
  map: MapMessage,
};

const ChatMessage = ({
  time,
  content,
  out,
  id,
  data,
  message,
  setReply,
  flatMessages,
  setOpenContact,
}) => {
  const isOther = !out;
  const URL = message?.Media?.Webpage?.URL;
  const isAudio = message?.Media?.Document?.MimeType?.includes("audio");
  const isVideo = message?.Media?.Document?.MimeType?.includes("video");
  const isContact = message?.Media?.PhoneNumber;
  const isMap = message?.Media?.Geo;
  const isFile = message?.Media?.Document?.Attributes?.find((item) =>
    item.hasOwnProperty("FileName")
  )?.FileName;
  const Image =
    message?.Media?.Photo || message?.Media?.links?.[0]?.includes("webp");
  const mergedMedia = message?.Media?.links && content;
  const geo = message?.Media?.Geo;
  const location = [geo?.Lat, geo?.Long] || [41.355747, 69.28795];
  const isCall = message?.Action?.Duration > 0;
  const isMissedCall = message?.Action?.Duration < 1;
  const formattedDate = moment.unix(time).format("HH:mm");
  const duration = moment.duration(message?.Action?.Duration, "seconds");
  const otherUserName = data?.Users?.find((user) => !user?.Self)?.Username;
  const formattedDuration =
    duration.asMinutes() >= 1
      ? `${Math.floor(duration.asMinutes())}min ${duration.seconds()}s`
      : `${duration.seconds()}s`;

  const messageType = useMemo(() => {
    const messageTypesMap = {
      audio: isAudio,
      video: isVideo,
      image: Image,
      link: URL,
      file: isFile,
      contact: isContact,
      map: isMap,
    };

    return (
      Object.keys(messageTypesMap).find((key) => messageTypesMap[key]) || null
    );
  }, [isAudio, isVideo, Image, URL, isFile, isContact, isMap]);

  const Messages = Renderers[messageType] || null;

  const onReply = () => setReply(message);

  return (
    <>
      <div
        className={`${styles.message} ${isOther ? styles.other : styles.self}`}
        id={`message-${id}`}
        key={id}
      >
        <div className={styles.header}>
          <span className={styles.sender}>{isOther && otherUserName}</span>
          <span className={styles.time}>{formattedDate || "--:--"}</span>
        </div>

        {Messages && (
          <Messages
            message={message}
            flatMessages={flatMessages}
            content={content}
            isMergedMedia={mergedMedia}
            url={`${TELEGRAM_CDN_URL}${message?.Media?.links?.[0]}`}
            isFile={isFile}
            setOpenContact={setOpenContact}
            location={location}
            isOther={isOther}
          />
        )}

        {content ? (
          <TextMessage
            message={message}
            flatMessages={flatMessages}
            content={content}
            isOther={isOther}
            urlMessage={URL}
            isMergedMedia={mergedMedia}
            formattedDate={formattedDate}
            formattedDuration={formattedDuration}
          />
        ) : null}

        {isMissedCall && (
          <div className={styles.missed_call}>
            <div className={styles.icon}>
              <PhoneCallIcon />
            </div>
            <div className={styles.content}>
              <p>Canceled call</p>
              <div className={styles.date}>
                {isOther ? <IncommingIcon /> : <OutgoingIcon />}
                <p>{formattedDate}</p>
              </div>
            </div>
          </div>
        )}

        {isCall && (
          <div className={styles.call}>
            <div className={styles.icon}>
              <PhoneCallIcon />
            </div>
            <div className={styles.content}>
              <p>Outgoing call</p>
              <div className={styles.date}>
                {isOther ? <IncommingIcon /> : <OutgoingIcon />}
                <p>
                  {formattedDate}, {formattedDuration}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className={styles.share}>
          <AdditionalFunctions message={message} onReply={onReply} />
        </div>
      </div>
    </>
  );
};

export default ChatMessage;
