import React, { useMemo } from "react";
import styles from "../../style.module.scss";
import AudioMessage from "../../../AudioMessage/AudioMessage";
import ImageMessage from "../ImageMessage";
import VideoMessage from "../VideoMessage";
import LinkMessage from "../LinkMessage";
import FileMessage from "../FileMessage";
import ContactMessage from "../ContactMessage";
import MapMessage from "../MapMessage";
import MissedCallMessage from "../MissedCallMessage/MissedCallComponent";
import CallMessage from "../MissedCallMessage";
import classNames from "classnames";
import { TELEGRAM_CDN_URL } from "../../../../../../utils/telegramApi";
import UrlImageMessage from "../ImageMessage/UrlImageMessage";

const Renderers = {
  audio: AudioMessage,
  image: ImageMessage,
  video: VideoMessage,
  link: LinkMessage,
  file: FileMessage,
  contact: ContactMessage,
  map: MapMessage,
  missed_call: MissedCallMessage,
  call: CallMessage,
};

const TextMessage = ({
  content,
  message,
  flatMessages,
  isOther,
  formattedDate,
  formattedDuration,
  isMergedMedia,
  urlMessage,
}) => {
  const replyMsg = flatMessages?.find(
    (item) => item?.ID === message?.ReplyTo?.ReplyToMsgID
  );
  const urlPhoto = message?.Media?.Webpage?.Type === "photo";
  const urlPhotoLink = message?.Media?.Webpage?.URL;
  const URL = replyMsg?.Media?.Webpage?.URL;
  const isAudio = replyMsg?.Media?.Document?.MimeType?.includes("audio");
  const Image = replyMsg?.Media?.Photo;
  const isVideo = replyMsg?.Media?.Document?.MimeType?.includes("video");
  const isContact = replyMsg?.Media?.PhoneNumber;
  const isMap = replyMsg?.Media?.Geo;
  const isCall = replyMsg?.Action?.Duration > 0;
  const isMissedCall = replyMsg?.Action?.Duration < 1;
  const isFile = replyMsg?.Media?.Document?.Attributes?.find((item) =>
    item.hasOwnProperty("FileName")
  )?.FileName;

  const messageType = useMemo(() => {
    const messageTypesMap = {
      audio: isAudio,
      video: isVideo,
      image: Image,
      link: URL,
      file: isFile,
      contact: isContact,
      map: isMap,
      missed_call: isMissedCall,
      call: isCall,
    };

    return (
      Object.keys(messageTypesMap).find((key) => messageTypesMap[key]) || null
    );
  }, [isAudio, isVideo, Image, URL, isFile, isContact, isMap, isMissedCall]);

  const ReplyMessages = Renderers[messageType] || null;

  return (
    <>
      <div
        className={classNames(styles.body, {
          [styles.merged]: isMergedMedia,
        })}
      >
        {message?.ReplyTo?.ReplyToMsgID && (
          <span className={styles.reply}>
            {ReplyMessages && (
              <ReplyMessages
                message={replyMsg}
                replyMessage={replyMsg?.Message}
                videoWidth="100%"
                videoHeight="100%"
                isOther={isOther}
                formattedDate={formattedDate}
                formattedDuration={formattedDuration}
                url={`${TELEGRAM_CDN_URL}${replyMsg?.Media?.links?.[0]}`}
              />
            )}
            {!URL && (
              <p
                dangerouslySetInnerHTML={{
                  __html: replyMsg?.Message?.replace(/\n/g, "<br>"),
                }}
              />
            )}
          </span>
        )}
        {urlPhoto && (
          <UrlImageMessage
            link={[urlPhotoLink]}
            isMergedMedia
            isOther={isOther}
          />
        )}
        {urlMessage && (
          <a
            href={urlMessage}
            target="_blank"
            rel="noreferrer"
            className={styles.url_content}
          >
            {urlMessage}
          </a>
        )}
        <p
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: content?.replace(/\n/g, "<br>")?.replace(urlMessage, ""),
          }}
        />
      </div>
    </>
  );
};

export default TextMessage;
