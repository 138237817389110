import React from "react";
import { IncommingIcon, PhoneCallIcon } from "../../../../../../assets/icons";
import styles from "./styles.module.scss";

const MissedCallMessage = ({ isOther, formattedDate }) => {
  return (
    <>
      <div className={styles.missed_call}>
        <div className={styles.icon}>
          <PhoneCallIcon />
        </div>
        <div className={styles.content}>
          <p>Canceled call</p>
          <div className={styles.date}>
            <IncommingIcon />
            <p>{formattedDate}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissedCallMessage;
