import axios from "axios";
import { store } from "../redux/store";
import { setToken, setUser } from "../redux/auth/authSlice";

export const TELEGRAM_BASE_URL = process.env.REACT_APP_TELEGRAM_BASE_URL;
export const TELEGRAM_CDN_URL = process.env.REACT_APP_TELEGRAM_CDN_URL;

export const telegramApi = axios.create({
  baseURL: TELEGRAM_BASE_URL,
  timeout: 200000,
});

const errorHandler = (error) => {
  const status = error.response?.status;

  if (status === 401) {
    store.dispatch(setToken(null));
    store.dispatch(setUser(null));

    window.location.reload();
  }

  return Promise.reject(error.response);
};

telegramApi.interceptors?.request.use(
  (config) => {
    const token = store.getState()?.auth?.token;

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (config.url === "upload")
      config.headers.set("Content-Type", "multipart/form-data");
    else {
      config.headers.set("Content-Type", "application/json");
    }

    return config;
  },

  (error) => errorHandler(error)
);

telegramApi.interceptors?.response.use((response) => {
  return response.data;
}, errorHandler);
