import { Avatar, Box, Button, Popover } from "@mui/material";
import styles from "./style.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from "../../../../assets/icons";
import { useSelector } from "react-redux";
import { isUserAdmin, isUserSupervisor, stringAvatar } from "../../../../utils";
import ContactCreateModal from "../Contact/Create";
import { useState } from "react";
import SessionCreateModal from "../Session/Create";
import SessionOtpModal from "../Session/OTP";
import SessionPasswordModal from "../Session/Password";
import useChats from "../../../../hooks/api/useChatsAPI";
import LogoutIcon from "@mui/icons-material/Logout";
import toast from "react-hot-toast";

const ChatHeader = ({ open, setOpen }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isChat = pathname.includes("chat");
  const { user } = useSelector((store) => store.auth);
  const isAdmin = isUserAdmin(user.role) || isUserSupervisor(user.role);
  const { telegramInfo, telegramLogout, refetchTelegramInfo } = useChats();

  const [openLogin, setOpenLogin] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const telegramUserOpen = Boolean(anchorEl);
  const id = telegramUserOpen ? "simple-popover" : undefined;
  const isAuth = telegramInfo?.payload?.Authorized;
  const profileName = telegramInfo?.payload?.Data?.AppName;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutFromTelegram = () => {
    handleClose();
    toast.promise(telegramLogout.mutateAsync(), {
      loading: "Logouting...",
      success: () => {
        refetchTelegramInfo();
        return "Success";
      },
      error: () => {
        return "Error";
      },
    });
  };

  return (
    <div className={styles.navbar}>
      {!isChat ? (
        <Box className={styles.title_box}>
          <div onClick={() => navigate("/chat")}>
            <ArrowBack />
          </div>
          <h2>Session</h2>
        </Box>
      ) : (
        <h2>Chat</h2>
      )}
      <div className={styles.flex}>
        <Button
          className={styles.add_contact}
          onClick={() =>
            setOpen({
              open: true,
              first_name: "",
              last_name: "",
              phone_number: "",
            })
          }
        >
          Add contact
        </Button>

        {isChat && isAdmin ? (
          <Button
            className={styles.session_button}
            onClick={() => navigate("/session")}
          >
            Sessions
          </Button>
        ) : (
          <>
            {isAuth ? (
              <Box onClick={handleClick}>
                <Avatar
                  style={{
                    width: "36px",
                    height: "36px",
                  }}
                  {...stringAvatar([profileName, profileName].join(" "))}
                />
              </Box>
            ) : (
              <Button
                className={styles.add_contact}
                onClick={() => setOpenLogin(true)}
              >
                Login
              </Button>
            )}
            <Popover
              id={id}
              open={telegramUserOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Button
                variant="outlinedSecondary"
                style={{
                  width: 120,
                }}
                onClick={handleLogoutFromTelegram}
              >
                Logout{" "}
                <LogoutIcon
                  style={{
                    marginLeft: "10px",
                    fontSize: 15,
                  }}
                />
              </Button>
            </Popover>
          </>
        )}
      </div>

      <ContactCreateModal open={open} setOpen={setOpen} />
      <SessionCreateModal
        userId={userId}
        open={openLogin}
        setOpen={setOpenLogin}
        setOtpOpen={setOtpOpen}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
      />
      <SessionOtpModal
        open={otpOpen}
        userId={userId}
        setOpen={setOtpOpen}
        phoneNumber={phoneNumber}
        setPasswordOpen={setPasswordOpen}
        setOtp={setOtp}
        otp={otp}
        refetchTelegramInfo={refetchTelegramInfo}
      />
      <SessionPasswordModal
        open={passwordOpen}
        setOpen={setPasswordOpen}
        userId={userId}
        phoneNumber={phoneNumber}
        otp={otp}
        refetchTelegramInfo={refetchTelegramInfo}
      />
    </div>
  );
};

export default ChatHeader;
