import { Outlet, useOutletContext, useParams } from "react-router-dom";
import styles from "./style.module.scss";
import ChatSidebar from "./components/Sidebar";
import Profile from "./components/Profile";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { setOpenProfile } from "../../redux/chat/chatSlice";
import useChats from "../../hooks/api/useChatsAPI";
import FieldMockUp from "./components/FIeldMockUp";

const ChatPage = () => {
  const inputRef = useRef();
  const {
    chats,
    chatsIsLoading,
    chatIsFetching,
    chatFetchNextPage,
    chatHasNextPage,
    setOpenContact,
  } = useOutletContext();
  const dispatch = useDispatch();
  const { id, hashId } = useParams();
  const [leadFormOpen, setLeadFormOpen] = useState(false);
  const [isPinField, setIsPinField] = useState(false);
  const [reply, setReply] = useState({});

  const { chat, chatRefetch } = useChats({
    id: id,
    hash: hashId,
  });

  useEffect(() => {
    dispatch(setOpenProfile(false));
  }, []);

  return (
    <div className={styles.chat}>
      <ChatSidebar
        data={chats}
        isLoading={chatsIsLoading}
        chatIsFetching={chatIsFetching}
        chatFetchNextPage={chatFetchNextPage}
        chatHasNextPage={chatHasNextPage}
        setLeadFormOpen={setLeadFormOpen}
        setReply={setReply}
        setIsPinField={setIsPinField}
      />
      <Outlet
        context={{
          chatData: chat?.payload,
          refetch: chatRefetch,
          inputRef: inputRef,
          setReply: setReply,
          reply: reply,
          setOpenContact: setOpenContact,
          isPinField: isPinField,
          setIsPinField: setIsPinField,
        }}
      />
      <Profile
        chatData={chat?.payload}
        refetch={chatRefetch}
        setLeadFormOpen={setLeadFormOpen}
        leadFormOpen={leadFormOpen}
      />
      {!id && <FieldMockUp />}
    </div>
  );
};

export default ChatPage;
