import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import styles from "./style.module.scss";
import ChatHeader from "../../pages/Chat/components/Header";
import useLayout from "./useLayout";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "../../components/ErrorPage";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const [openContact, setOpenContact] = useState(false);

  const Renderer =
    pathname.includes("/chat") || pathname.includes("/session")
      ? ChatHeader
      : Navbar;

  const {
    chats,
    chatsIsLoading,
    chatIsFetching,
    chatsRefetch,
    chatsIsError,
    chatFetchNextPage,
    chatHasNextPage,
  } = useLayout();

  return (
    <div className={styles.container}>
      <Sidebar />

      <div className={styles.wrapper}>
        <Renderer setOpen={setOpenContact} open={openContact} />
        <div className={styles.content}>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <Outlet
              context={{
                chats,
                chatsIsLoading,
                chatIsFetching,
                chatsRefetch,
                chatsIsError,
                chatFetchNextPage,
                chatHasNextPage,
                setOpenContact,
                openContact,
              }}
            />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default Layout;
