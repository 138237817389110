import { Button, CircularProgress, Dialog } from "@mui/material";
import styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { AddSessionIcon } from "../../../../../assets/icons";
import OtpInput from "react-otp-input";
import useSessionAPI from "../../../../../hooks/api/useSessionAPI";
import toast from "react-hot-toast";
import { useState } from "react";

export default function SessionOtpModal({
  open,
  setOpen,
  phoneNumber,
  userId,
  setPasswordOpen,
  otp,
  setOtp,
  refetchTelegramInfo,
}) {
  const { authTelegram } = useSessionAPI();
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoader(true);

    const payload = {
      phone: phoneNumber,
      code: otp,
      password: "",
      user_id: userId,
    };

    toast.promise(authTelegram.mutateAsync(payload), {
      loading: "Checking otp...",
      success: (res) => {
        setOtp("");
        if (res.payload.code.includes("password")) {
          handleClose();
          setLoader(false);
          setPasswordOpen(true);
        } else {
          setLoader(false);
          handleClose();
          refetchTelegramInfo();
        }
        return "Success";
      },
      error: () => {
        setLoader(false);
        return "Error on sending phone number!";
      },
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <div className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <div className={styles.header}>
          <div className={styles.add_user}>
            <AddSessionIcon />
          </div>
          <div className={styles.content}>
            <h3>Add Sessions for Agents</h3>
            <p>Create you sessions agents</p>
          </div>
        </div>
        <div className={styles.body} onKeyDown={handleKeyDown}>
          <p className={styles.confirm_text}>
            To confirm the phone, we sent a 5-digit code to {phoneNumber}
          </p>
          <OtpInput
            numInputs={5}
            value={otp}
            inputType="tel"
            renderInput={(props) => <input {...props} />}
            onChange={(e) => setOtp(e)}
            containerStyle={styles.verification_input}
          />
          <p className={styles.resend}>
            Didn't receive the SMS code? <span>Send again</span>
          </p>
        </div>
        <div className={styles.footer}>
          <Button className={styles.cancel_button} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loader}
            className={styles.save_button}
            onClick={onSubmit}
          >
            {loader ? (
              <CircularProgress
                style={{
                  color: "#fff",
                }}
                size={16}
              />
            ) : (
              "Add"
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
