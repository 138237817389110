import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import {
  AudioTrashIcon,
  ChatSendIcon,
  RecordingIcon,
} from "../../../../assets/icons";
import useChats from "../../../../hooks/api/useChatsAPI";
import CustomAudioPlayer from "../AudioMessage";
import ChatTextArea from "./ChatTextArea";
import styles from "./style.module.scss";
import useTool from "./useTool";

const ChatTools = ({
  isUpload = false,
  chatRefetch,
  messageRefetch,
  handleScrollDown,
  reply,
  setReply,
  setMessage,
  message,
  isLoading,
  setIsLoading,
  onMultipleFileAdd,
}) => {
  const { id, hashId } = useParams();
  const audio = new Audio("/sound/bubbleSound.mp3");

  const {
    recording,
    audioURL,
    timer,
    startRecording,
    stopRecording,
    handleDeleteAudio,
    formatTime,
    audioMessage,
    clearAudio,
  } = useTool({ setLoader: setIsLoading });

  const isRecording = !audioURL && recording;
  const isUrlValid = audioURL && !recording;
  const invalidRecord = !audioURL && !recording;

  const { sendMessage, sendMessageLoading } = useChats();

  const handleSendMessage = () => {
    const payload = {
      id: id,
      hashId: hashId,
      file_urls: audioMessage ? [audioMessage] : undefined,
      message: message,
      audio_duration: timer || undefined,
      reply_to_message_id: reply?.ID,
    };
    sendMessage(payload, {
      onSuccess: () => {
        audio.play();
        messageRefetch();
        chatRefetch();
        clearAudio();
        setMessage("");
        setReply({});
        setTimeout(() => {
          handleScrollDown();
        }, 1500);
      },
      onError: (err) => {
        if (err?.data?.message) {
          toast.error(err?.data?.message);
        } else {
          toast.error("Error in sending Message!");
        }
      },
    });
  };

  return (
    <>
      <ChatTextArea
        sendMessageLoading={sendMessageLoading}
        handleSendMessage={handleSendMessage}
        onMultipleFileAdd={onMultipleFileAdd}
        setIsLoading={setIsLoading}
        setMessage={setMessage}
        message={message}
        isUpload={isUpload}
        invalidRecord={invalidRecord}
        recording={recording}
        startRecording={startRecording}
        stopRecording={stopRecording}
      />
      {isRecording && (
        <Box className={styles.recording_box}>
          <div className={styles.timer}>
            <span className={styles.dot} />
            <p className={styles.timer_text}> {formatTime(timer)}</p>
          </div>
          <p className={styles.cancel} onClick={handleDeleteAudio}>
            Cancel
          </p>

          <Box className={styles.recording} onClick={stopRecording}>
            <RecordingIcon />
          </Box>
        </Box>
      )}
      {isUrlValid && (
        <Box className={styles.recording_box}>
          <Box className={styles.recording_trash} onClick={handleDeleteAudio}>
            <AudioTrashIcon />
          </Box>

          <CustomAudioPlayer url={audioURL} timer={timer} />
        </Box>
      )}
      {!isUpload && (
        <Button
          disabled={sendMessageLoading || isLoading}
          onClick={handleSendMessage}
          className={styles.sendButton}
        >
          {sendMessageLoading || isLoading ? (
            <CircularProgress
              style={{
                color: "#fff",
              }}
              size={16}
            />
          ) : (
            <ChatSendIcon />
          )}
        </Button>
      )}
    </>
  );
};

export default ChatTools;
