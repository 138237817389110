import {
  Box,
  Button,
  Checkbox,
  Modal,
  Popover,
  Typography,
} from "@mui/material";
import styles from "./styles.module.scss";
import { PinIcon, ShareIcon, UnPinIcon } from "../../../../assets/icons";
import { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useOutletContext, useParams } from "react-router-dom";
import useChats from "../../../../hooks/api/useChatsAPI";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "12px",
  textAlign: "center",
  p: 4,
};

const AdditionalFunctions = ({ onReply, message }) => {
  const { id, hashId } = useParams();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [oneside, setOneSide] = useState(false);
  const open = Boolean(anchorEl);
  const menuId = open ? "session-simple-popover" : undefined;
  const { chatData } = useOutletContext();
  const { pinMessage } = useChats();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorPosition({
      top: event.clientY,
      left: event.clientX,
    });
  };

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorPosition(null);
  };

  const onPinMessage = () => {
    const payload = {
      hashId: hashId,
      id: id,
      message_id: message?.ID,
      oneside: oneside,
      fields: {
        pinned: true,
      },
    };

    toast.promise(pinMessage.mutateAsync(payload), {
      loading: "Pinning...",
      success: () => {
        toggleModal();
        handleClose();
        queryClient.refetchQueries(["GET_PINNED_MESSAGES", id, hashId]);
        return "Success";
      },
      error: () => {
        return "Error";
      },
    });
  };

  const unPinMessage = () => {
    const payload = {
      hashId: hashId,
      id: id,
      message_id: message?.ID,
      oneside: oneside,
      fields: {
        pinned: false,
      },
    };

    toast.promise(pinMessage.mutateAsync(payload), {
      loading: "Pinning...",
      success: () => {
        queryClient.refetchQueries(["GET_PINNED_MESSAGES", id, hashId]);
        handleClose();
        return "Success";
      },
      error: () => {
        return "Error";
      },
    });
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <MoreHorizIcon
        aria-describedby={menuId}
        onClick={handleClick}
        style={{
          cursor: "pointer",
        }}
      />
      <Popover
        id={menuId}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          width={160}
          display="flex"
          flexDirection="column"
          className={styles.popover_container}
        >
          <Box className={styles.wrapper}>
            <Button
              variant="outlinedSecondary"
              className={styles.button}
              onClick={() => {
                handleClose();
                onReply();
              }}
            >
              <ShareIcon />
              Reply
            </Button>
            {message?.Pinned ? (
              <Button
                onClick={unPinMessage}
                variant="outlinedSecondary"
                className={styles.button}
              >
                <UnPinIcon />
                Unpin
              </Button>
            ) : (
              <Button
                onClick={toggleModal}
                variant="outlinedSecondary"
                className={styles.button}
              >
                <PinIcon />
                Pin
              </Button>
            )}
          </Box>
        </Box>
      </Popover>

      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Want to pin this message?
          </Typography>
          <Typography
            mt={2}
            mb={2}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <Checkbox
              value={oneside}
              onChange={(e) => setOneSide(e.target.checked)}
            />{" "}
            Pin me and{" "}
            {`${chatData?.Users?.[0]?.LastName || ""} ${
              chatData?.Users?.[0]?.FirstName || "User"
            }`}
          </Typography>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            gap="10px"
            mt="15px"
          >
            <Button
              variant="outlined"
              onClick={toggleModal}
              className={styles.cancel_button}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={styles.save_button}
              onClick={onPinMessage}
            >
              Pin
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AdditionalFunctions;
