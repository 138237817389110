import { Button, CircularProgress, Dialog, TextField } from "@mui/material";
import styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { AddSessionIcon } from "../../../../../assets/icons";
import FRow from "../../../../../components/FRow";
import useSessionAPI from "../../../../../hooks/api/useSessionAPI";
import toast from "react-hot-toast";
import { useState } from "react";

export default function SessionCreateModal({
  open,
  setOpen,
  userId,
  setOtpOpen,
  setPhoneNumber,
  phoneNumber,
}) {
  const { authTelegram } = useSessionAPI();
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setLoader(true);
    const cleanedPhoneNumber = phoneNumber?.replace(/\s+/g, "");
    const payload = {
      phone: cleanedPhoneNumber,
      code: "",
      password: "",
      user_id: userId,
    };

    toast.promise(authTelegram.mutateAsync(payload), {
      loading: "Sending phone number...",
      success: (res) => {
        if (res.payload.code === "done") {
          handleClose();
          setLoader(false);
          return "Success";
        } else {
          handleClose();
          setOtpOpen(true);
          setLoader(false);
          return "Success";
        }
      },
      error: () => {
        setLoader(false);
        return "Error on sending phone number!";
      },
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <div className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <div className={styles.header}>
          <div className={styles.add_user}>
            <AddSessionIcon />
          </div>
          <div className={styles.content}>
            <h3>Add Sessions for Agents</h3>
            <p>Create you sessions agents</p>
          </div>
        </div>
        <div className={styles.body}>
          <FRow label="Phone number">
            <TextField
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              placeholder="Enter phone"
              style={{
                background: "#fff",
              }}
            />
          </FRow>
        </div>
        <div className={styles.footer}>
          <Button className={styles.cancel_button} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!phoneNumber || loader}
            className={styles.save_button}
            onClick={onSubmit}
          >
            {loader ? (
              <CircularProgress
                style={{
                  color: "#fff",
                }}
                size={16}
              />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
