import React from "react";
import { Box } from "@mui/material";
import moment from "moment";
import styles from "./styles.module.scss";
import ChatMessage from "../Message";
import ArrowLeft from "../../../../assets/icons";

const PinnedMessages = ({
  pinMessages,
  handleScrollDown,
  setReply,
  scrollHeight,
  setIsPinField,
  flatMessages,
}) => {
  if (!pinMessages || pinMessages?.length === 0) {
    return <div className={styles.emptyState}>No pinned messages</div>;
  }

  return (
    <div className={styles.field}>
      <Box className={styles.header}>
        <div className={styles.content}>
          <div className={styles.icon} onClick={() => setIsPinField(false)}>
            <ArrowLeft />
          </div>
          <p>{pinMessages?.length || 0} Pinned messages</p>
        </div>
      </Box>
      <div
        className={styles.pinnedMessages}
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          scrollBehavior: "smooth",
          overflow: "auto",
        }}
      >
        {pinMessages.map((message, index) => {
          const messageDate = moment.unix(message?.Date).format("YYYY-MM-DD");
          return (
            <div key={message?.ID}>
              <div className={styles.dateSeparator}>
                {moment(messageDate).calendar(null, {
                  sameDay: "[Today]",
                  lastDay: "[Yesterday]",
                  lastWeek: "D MMMM YYYY",
                  sameElse: "D MMMM YYYY",
                })}
              </div>
              <ChatMessage
                key={message?.ID}
                id={message?.ID}
                out={message?.Out}
                time={message?.Date}
                content={message?.Message}
                senderType={message?.senderType}
                message={message}
                nextMessage={pinMessages[index + 1] || null}
                prevMessage={pinMessages[index - 1] || null}
                setReply={setReply}
                flatMessages={flatMessages}
              />
            </div>
          );
        })}
        {scrollHeight < -500 && (
          <Box onClick={handleScrollDown} className={styles.scrollBottom}>
            Scroll to bottom
          </Box>
        )}
      </div>
    </div>
  );
};

export default PinnedMessages;
