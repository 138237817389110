import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { AttachmentIcon, MicroPhoneIcon } from "../../../../assets/icons";
import EmojiPicker from "../EmojiPicker";
import styles from "./style.module.scss";

const ChatTextArea = ({
  isUpload = false,
  setMessage,
  message,
  onMultipleFileAdd,
  handleSendMessage,
  sendMessageLoading,
  invalidRecord,
  recording,
  stopRecording,
  startRecording,
}) => {
  const clickHandler = () => {
    const handler = document.getElementById("inputFile");
    handler.click();
  };

  const handleEmojiSelect = (emoji) => {
    setMessage(message + emoji.native);
  };

  return (
    <>
      {invalidRecord && (
        <TextField
          variant="outlined"
          placeholder="Message"
          key={sendMessageLoading || isUpload}
          autoFocus
          value={message}
          disabled={sendMessageLoading}
          multiline
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
          style={{
            height: "100%",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <IconButton
                  disabled={sendMessageLoading}
                  onClick={clickHandler}
                >
                  <input
                    id="inputFile"
                    type="file"
                    hidden
                    multiple
                    onChange={(e) => {
                      onMultipleFileAdd(Array.from(e.target.files));
                      e.target.value = "";
                    }}
                  />
                  <AttachmentIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment disablePointerEvents={sendMessageLoading}>
                <EmojiPicker onSelect={handleEmojiSelect} />
                {!isUpload && (
                  <IconButton
                    disabled={sendMessageLoading}
                    onClick={recording ? stopRecording : startRecording}
                    className={recording ? styles.recording : ""}
                  >
                    <MicroPhoneIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          className={styles.messageInput}
        />
      )}
    </>
  );
};

export default ChatTextArea;
