import { Button, CircularProgress, Dialog, TextField } from "@mui/material";
import styles from "./styles.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { AddSessionIcon } from "../../../../../assets/icons";
import FRow from "../../../../../components/FRow";
import useSessionAPI from "../../../../../hooks/api/useSessionAPI";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";

export default function ContactCreateModal({ open, setOpen }) {
  const queryClient = useQueryClient();
  const [loader, setLoader] = useState(false);
  const { addContactTelegram } = useSessionAPI();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      first_name: open?.first_name || "",
      last_name: open?.last_name || "",
      phone_number: open?.phone_number || "",
    },
  });

  const handleClear = () => {
    reset({
      first_name: "",
      last_name: "",
      phone_number: "",
    });
  };

  useEffect(() => {
    reset({
      first_name: open?.first_name || "",
      last_name: open?.last_name || "",
      phone_number: open?.phone_number || "",
    });
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const onSubmit = (values) => {
    setLoader(true);
    const payload = {
      ...values,
      message: "Assalomu Alaykum",
    };

    toast.promise(addContactTelegram.mutateAsync(payload), {
      loading: "Creating new contact...",
      success: (res) => {
        setLoader(false);
        handleClear();
        handleClose();
        queryClient.refetchQueries(["GET_CHATS"]);
        return "Success";
      },
      error: (err) => {
        setLoader(false);
        handleClear();
        return "The user with this number is not yet registered on Telegram.";
      },
    });
  };

  return (
    <Dialog
      open={open?.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <div className={styles.header}>
          <div className={styles.add_user}>
            <AddSessionIcon />
          </div>
          <div className={styles.content}>
            <h3>Add Contact</h3>
            <p>Add your Information</p>
          </div>
        </div>
        <div className={styles.body}>
          <FRow label="First name" required>
            <Controller
              name="first_name"
              key={"first_name"}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter first name"
                  style={{
                    background: "#fff",
                  }}
                  required
                />
              )}
            />
          </FRow>

          <FRow label="Last name">
            <Controller
              name="last_name"
              key={"last_name"}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter last name"
                  style={{
                    background: "#fff",
                  }}
                />
              )}
            />
          </FRow>
          <FRow label="Phone number" required>
            <Controller
              name="phone_number"
              key={"phone_number"}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Enter phone"
                  style={{
                    background: "#fff",
                  }}
                  required
                />
              )}
            />
          </FRow>
        </div>
        <div className={styles.footer}>
          <Button className={styles.cancel_button} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loader}
            className={styles.save_button}
            type="submit"
          >
            {loader ? (
              <CircularProgress
                style={{
                  color: "#fff",
                }}
                size={16}
              />
            ) : (
              "Add"
            )}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
