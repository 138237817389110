import { Box } from "@mui/material";
import React from "react";
import ReactPlayer from "react-player";
import styles from "../../style.module.scss";
import classNames from "classnames";
import { TELEGRAM_CDN_URL } from "../../../../../../utils/telegramApi";

const VideoMessage = ({
  message,
  videoWidth = "640px",
  videoHeight = "360px",
  isMergedMedia,
  isOther,
}) => {
  return (
    <>
      <Box
        className={classNames(styles.video, {
          [styles.merged]: isMergedMedia,
          [styles.other]: isOther,
        })}
      >
        <ReactPlayer
          height={videoHeight}
          width={videoWidth}
          style={{
            minWidth: "300px",
            minHeight: "200px",
          }}
          controls
          url={`${TELEGRAM_CDN_URL}${message?.Media?.links?.[0]}`}
        />
      </Box>
    </>
  );
};

export default VideoMessage;
