import React from "react";
import {
  IncommingIcon,
  OutgoingIcon,
  PhoneCallIcon,
} from "../../../../../../assets/icons";
import styles from "./styles.module.scss";

const CallMessage = ({ isOther, formattedDate, formattedDuration }) => {
  return (
    <>
      <div className={styles.call}>
        <div className={styles.icon}>
          <PhoneCallIcon />
        </div>
        <div className={styles.content}>
          <p>Outgoing call</p>
          <div className={styles.date}>
            {isOther ? <IncommingIcon /> : <OutgoingIcon />}
            <p>
              {formattedDate}, {formattedDuration}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallMessage;
