import React from "react";
import styles from "../../style.module.scss";

const LinkMessage = ({ message, replyMessage }) => {
  const URL = message?.Media?.Webpage?.URL;

  return (
    <>
      <div className={styles.body}>
        <a
          href={URL}
          target="_blank"
          rel="noreferrer"
          className={styles.url_content}
        >
          {URL}
        </a>

        <p
          className={styles.url_text}
          dangerouslySetInnerHTML={{
            __html: replyMessage.replace(/\n/g, "<br>")?.replace(URL, ""),
          }}
        />
      </div>
    </>
  );
};

export default LinkMessage;
