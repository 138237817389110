import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
// import { store } from "../store";
// import { setToken, setUser } from "../auth/authSlice";

const initialState = { visitedTime: moment(new Date()) };

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    patchVisitedTime(state, { payload }) {
      const diff = moment(new Date()).diff(moment(state.visitedTime), "minute");

      if (Number(diff) >= 120) {
        payload();
        return;
      }

      state.visitedTime = moment(new Date());
    },
    setVisitedTime(state) {
      state.visitedTime = moment(new Date());
    },
    checkVisitedTime(state, { payload }) {
      const diff = moment(new Date()).diff(moment(state.visitedTime), "minute");

      if (Number(diff) >= 120) {
        payload();
      }
    },
  },
});

export const { patchVisitedTime, setVisitedTime, checkVisitedTime } =
  settingsSlice.actions;

export default settingsSlice.reducer;
