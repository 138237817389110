import React, { useMemo, useState } from "react";
import { CloseIcon, PlusIcon } from "../../../../assets/icons";
import styles from "./style.module.scss";
import { Avatar, Button } from "@mui/material";
import QuickLeadForm from "../QuickLeadForm";
import LeadCard from "../LeadCard";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { setOpenProfile } from "../../../../redux/chat/chatSlice";
import { FSelect } from "../../../../components/Select";
import { chatSelectStyles } from "../../../../components/Select/styles";
import { useDebounce } from "use-debounce";
import useUsers from "../../../../hooks/api/useUsersAPI";
import {
  isUserAdmin,
  isUserSupervisor,
  stringSingleAvatar,
} from "../../../../utils";
import useChats from "../../../../hooks/api/useChatsAPI";
import useOrderAPI from "../../../../hooks/api/useOrderAPI";
import { useParams } from "react-router-dom";

const Profile = ({ chatData, refetch, setLeadFormOpen, leadFormOpen }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);
  const { openProfile } = useSelector((store) => store.chat);
  const [debouncedSearch] = useDebounce(search, 500);
  const { user } = useSelector((store) => store.auth);
  const isAdmin = isUserAdmin(user.role) || isUserSupervisor(user.role);

  const { agentAssign } = useChats();

  const {
    data: chatLeads,
    isLoading,
    refetch: chatRefetch,
  } = useOrderAPI({
    isGetOrders: true,
    tg_chat_id: id,
  });

  const { data } = useUsers({
    enabled: true,
    amount: 30,
    search: debouncedSearch,
  });

  const agentOptions = useMemo(() => {
    return data?.users?.rows?.map((item) => ({
      label: `${item.last_name} ${item.first_name}`,
      value: item?.id,
      user_id: item?.id,
    }));
  }, [data]);

  const handleAssignAgent = (value) => {
    const payload = {
      user_id: 0,
      chat_id: chatData?.FullUser?.ID,
      user_key: value?.user_id,
      order_id: chatLeads?.orders?.rows.filter(
        (item) => item.lead.status === "active"
      )?.[0]?.id,
    };
    agentAssign.mutate(payload, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  const filteredChatLeads = chatLeads?.orders?.rows?.filter(
    (item) => item?.lead?.status === "active"
  );

  return (
    <div
      className={classNames(styles.profile, {
        [styles.open]: openProfile,
      })}
    >
      <div className={styles.header}>
        <p className={styles.header_text}>Personal Info</p>
        <div
          className={styles.close_icon}
          onClick={() => {
            dispatch(setOpenProfile(false));
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className={styles.personal_info}>
        {/* <img alt="ava" src="/chat_ava.jpg" /> */}
        <Avatar
          {...stringSingleAvatar(
            [
              chatData?.Users?.[0]?.LastName ||
                chatData?.Users?.[0]?.FirstName ||
                chatData?.Users?.[0]?.Title,
            ].join(" ")
          )}
        />
        <div className={styles.desc}>
          <p className={styles.name}>
            {`${chatData?.Users?.[0]?.LastName}  ${chatData?.Users?.[0]?.FirstName}` ||
              chatData?.Users?.[0]?.Title}
          </p>
          {chatData?.Users?.[0]?.Phone && (
            <p className={styles.phone}>+{chatData?.Users?.[0]?.Phone}</p>
          )}
        </div>
      </div>
      <div className={styles.assigned_agent}>
        <p className={styles.assigned_agent_text}>Assigned agent</p>
        <FSelect
          components={{
            IndicatorSeparator: null,
          }}
          value={agentOptions?.find(
            (el) => el?.user_id === chatData?.Agent?.Id
          )}
          styles={chatSelectStyles}
          options={agentOptions}
          isDisabled={!isAdmin}
          placeholder="Select agent"
          onChange={handleAssignAgent}
          onInputChange={(e) => setSearch(e)}
          isClearable
        />
      </div>
      <div
        className={classNames(styles.lead_section, {
          [styles.open]: !leadFormOpen,
        })}
      >
        <Button
          className={styles.lead_button}
          color="inherit"
          variant="outlined"
          onClick={() => setLeadFormOpen(true)}
          disabled={filteredChatLeads?.length}
        >
          Quick create lead
          <PlusIcon />
        </Button>
      </div>
      <div
        className={classNames(styles.quick_lead, {
          [styles.open]: leadFormOpen,
        })}
      >
        <QuickLeadForm isConfirmed={false} setLeadFormOpen={setLeadFormOpen} />
      </div>
      <div className={styles.lead_list}>
        {chatLeads?.orders?.rows?.map((item) => (
          <LeadCard data={item} key={item?.id} />
        ))}
      </div>
    </div>
  );
};

export default Profile;
