import React, { useState } from "react";
import styles from "./styles.module.scss";
import GalleryPopup from "../../../Gallery";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import classNames from "classnames";
import { TELEGRAM_CDN_URL } from "../../../../../../utils/telegramApi";

const ImageMessage = ({ message, isMergedMedia, isOther }) => {
  const [openGallery, setOpenGallery] = useState(false);

  const gallery = message?.Media?.links?.map((item) => ({
    original: `${TELEGRAM_CDN_URL}${item}`,
    thumbnail: `${TELEGRAM_CDN_URL}${item}`,
  }));

  return (
    <>
      <LazyLoadImage
        alt="default"
        className={classNames(styles.image, {
          [styles.merged]: isMergedMedia,
          [styles.other]: isOther,
        })}
        effect="blur"
        onClick={() => setOpenGallery(true)}
        src={
          `${TELEGRAM_CDN_URL}${message?.Media?.links?.[0]}` ||
          "/default_image.png"
        }
      />
      <GalleryPopup
        isOpen={openGallery}
        close={() => setOpenGallery(false)}
        images={gallery}
      />
    </>
  );
};

export default ImageMessage;
